.mainContainer {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  font-family: 'Oxygen';
  font-weight: bold !important;
  flex-wrap: wrap;
  font-weight: normal;
}

.letterWrapper {
  border: 1px solid #000;
  max-width: 700px;
  padding: 20px 40px;
}

.letterContainer {
  padding: 0;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.text {
  color: #000 !important;
  margin-bottom: 20px;
}

.logoContainer {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.logoContainer h5 {
  margin: 0;
  font-weight: bolder !important;
}

.logoContainer img {
  height: 50px;
  margin-right: 15px;
}

.UFSTeamText {
  margin: 20px 0 80px 0;
}

.legalText {
  font-style: italic;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-end;
  margin-top: 30px;
  margin-left: 10px;
}

.buttonsContainer button {
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  outline: none !important;
}

.inputContainer {
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #000;
  outline: none;
  padding: 0 5px;
}

.sendEmailBtn {
  outline: none !important;
}

.sendToSelfButton:disabled, .sendEmailBtn:disabled {
  background: rgb(240, 240, 240);
}

.sentButton, .downloadedButton {
  background-color: #ff5802 !important;
  color: #fff;
  font-weight: bold;
  border: none !important;
  outline: none !important;
}

.buyerPrequalTable {
  margin-top: 50px;
}

.buyerPrequalTable, .buyerPrequalTable tr, .buyerPrequalTable td {
  border: 1px solid #000;
  font-size: 14px !important;
  font-weight: normal;
}

.buyerPrequalTable tr, .buyerPrequalTable td {
  padding: 3px !important;
}

.tableHeader {
  background: rgb(230, 230, 230);
  font-weight: bold !important;
  text-align: center;
}

hr {
  margin: 60px 0 !important;
}

@media (max-width: 992px) {
  .mainContainer {
    flex-direction: column;
    padding: 40px 10px;
  }

  .letterWrapper {
    width: 80vw;
    margin: 0 40px;
  }

  .letterContainer {
    padding: 10px;
  }

  .buttonsContainer {
    align-self: center;
    width: 70vw !important;
    max-width: 70vw;
    flex: 1;
    align-items: center;
  }

  .buttonsContainer button, .inputContainer {
    width: 300px;
  }
}   

@media (max-width: 576px) {
  .letterWrapper {
    font-size: 14px !important;
    padding: 20px 10px !important;
  }

  .buttonsContainer button {
    font-size: 14px;
    padding: 5px 10px;
  }

  .buttonsContainer button, .inputContainer {
    width: 250px;
  }

  .buyerPrequalTable {
    overflow-x: scroll;
  }

  .buyerPrequalTable, .buyerPrequalTable tr, .buyerPrequalTable td {
    font-size: 12px !important;
  }

  .buyerPrequalTable tr, .buyerPrequalTable td {
    padding: 1px !important;
  }

  .buyerPrequalTable {
    overflow-wrap: break-word;
    table-layout: fixed;
    width: 100%;
  }
}   

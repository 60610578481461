.mainContainer {
  width: 100vw;
  min-height: 100vh;
  color: #000;
  font-family: 'Oxygen';
  padding: 20px 20px 120px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.illustration {
  width: 50vw;
}

.mainContainer h1 {
  font-weight: bold;
  margin-bottom: 10px;
}

.getStartedBtn {
  background-color: #ff5802 !important;
  border-radius: 5px;
  padding: 10px 40px;
  outline: none !important;
  border: none !important;
  color: #fff;
  margin-top: 40px;
  transition: all 0.2s;
  font-weight: bold;
}

.getStartedBtn:hover {
  color: #fff;
}

.footer {
  background-color: #101333;
  width: 100vw;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
  position: absolute;
  bottom: 0;
}

.footer p {
  margin: 0;
  font-size: 18px;
}

.logo {
  height: 40px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .illustration {
    width: 90vw;
  }

  .mainContainer h1 {
    font-size: 30px;
  }

  .getStartedBtn {
    padding: 5px 40px;
  }

  .mainText {
    font-size: 20px;
  }

  .footer p {
    font-size: 14px;
  }

  .logo {
    height: 20px;
  }
}   

.mainContainer {
  width: 100vw;
  min-height: 800px;
  height: fit-content;
  font-family: 'Oxygen';
  display: flex;
  overflow: hidden;
}

.leftPane, .rightPane, .sectionsContainer, .sectionCompleteContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leftPane {
  position: relative;
  z-index: 3;
  background-color: #101333;
  padding: 90px 10px;
  width: 30vw;
  height: 100vh;
  color: #fff;
  min-height: 800px;
}

.rightPane {
  color: #000 !important;
  padding: 60px 10px;
  height: 100vh !important;
  width: 70vw;
  position: relative;
  text-align: center;
  overflow: hidden;
  min-height: 800px;
}

.sectionsContainer {
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.sectionContainer, .sectionCompleteContainer {
  text-align: center;
}

.sectionCompleteContainer {
  position: fixed;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 4;
  background: #101333;
  color: #fff;
  padding: 50px 30px;
  justify-content: center;
}

.sectionCompleteContainer h2, .sectionCompleteContainer h3 {
  width: 60%;
}

.logo {
  height: 50px;
  margin-bottom: 30px;
}

.errorMessage {
  height: 30px;
}

.activeSection h5 {
  font-weight: bold;
  color: #fff;
  font-size: 1.35em;
}

.inactiveSection h5 {
  font-weight: normal;
  color: rgb(176, 185, 175);
}

.inputContainer {
  width: 60%;
  background-color: #d8d8d8;
  margin: 40px 0 20px 0;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 10px;
  text-align: center;
  color: rgb(70, 70, 70);
  font-size: 18px;
  text-align-last: center;
}

.numberInputContainer, .firstInputContainer, .secondInputContainer, .franchiseInputContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin-right: 10vw;
}

.numberInputContainer {
  width: 100%;
  margin-right: 0;
  justify-content: center;
}

.franchiseInputContainer, .homeInputContainer, .mortgageInputContainer, .employmentInputContainer {
  flex-direction: column;
  margin-right: 0;
  width: 100%;
}

.franchiseNameInputContainer, .houseValueInput, .mortgageValueInput, .employmentInput {
  opacity: 0;
  display: none;
}

.franchiseNameInputContainer .inputContainer {
  margin-bottom: 25px !important;
}

.firstInputContainer .inputContainer, .secondInputContainer .inputContainer {
  width: 60%
}

.firstInputContainer p, .secondInputContainer p {
  width: 30%;
  text-align: right;
}

.numberInputContainer p, .firstInputContainer p, .secondInputContainer p {
  margin-right: 20px;
}

.numberInputContainer p {
  margin-bottom: -20px;
}

.firstInputContainer p {
  margin-bottom: 0px !important;
}

.secondInputContainer p {
  margin-bottom: 60px !important;
}

.firstInputContainer .inputContainer {
  margin: 40px 0 40px 0 !important;
}

.secondInputContainer .inputContainer {
  margin: -30px 0 30px 0 !important;
}

.numberInputField::-webkit-outer-spin-button, .numberInputField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdownContainer {
  border-right: 7px solid #d8d8d8;
}

.questionText, .headingText {
  width: 60%;
}

.questionText {
  width: 60%;
  font-weight: bolder;
}

.headingText {
  margin-bottom: 10px;
}

.nextBtn, .backBtn, .continueBtn, .skipBtn, .optionBtn, .selectedOption {
  position: absolute;
  background-color: #ff5802;
  border-radius: 5px;
  padding: 5px 40px;
  right: 100px;
  bottom: 130px;
  outline: none !important;
  border: none !important;
  color: #fff;
  transition: all 0.2s;
  font-weight: bold;
}

.infoMessage, .sectionCompleteContainer p {
  position: absolute;
  bottom: 10px;
  width: 80%;
}

.infoMessage a {
  color: inherit !important;
  text-decoration: underline;
}

.backBtn, .optionBtn {
  border: 1px solid rgb(147, 147, 147) !important;
  background-color: transparent;
  right: unset;
  left: 100px;
  color: #000;
}

.continueBtn, .selectedOption {
  position: initial;
  width: 200px;
  background-color: #ff5802;
  color: #fff;
}

.sectionCompleteContainer .backBtn {
  position: initial;
  margin-top: 20px;
  width: 200px;
  border-color: #fff;
  color: #fff;
}

.skipBtn {
  background-color: transparent;
  border: none;
  color: #000;
  bottom: 130px !important;
  left: unset !important;
  right: 250px !important;
  width: fit-content;
  padding: 5px 0;
}

.selectedOption, .optionBtn {
  position: initial;
  width: 100px;
  margin: 10px;
}

.selectedOption {
  border: none !important
}

.backBtn:hover, .skipBtn:hover {
  color: #ff5802;
}

.nextBtn:focus, .nextBtn:active, .continueBtn:active {
  border: 1px solid #fff !important;
}

.hidden {
  display: none;
}

.shown {
  display: inherit;
}

.sectionCompleteContainer h3 {
  margin-bottom: 70px;
}

.sectionCompleteContainer p {
  color: #fff !important;
  width: 70%;
  font-size: 14px;
}

.sectionCompleteContainer p a {
  color: #fff !important;
  text-decoration: underline;
}

.headingText {
  margin-top: 30px;
}

.spinnerContainer {
  height: 50px;
  width: 50px;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .numberInputContainer, .firstInputContainer, .secondInputContainer {
    width: 95%;
  }

  .firstInputContainer p, .secondInputContainer p {
    font-size: 14px;
  }

  .mainContainer {
    flex-direction: column;
  }

  .infoMessage {
    width: 80%;
  }

  .nextBtn, .backBtn, .skipBtn {
    position: inherit;
    width: 200px;
  }

  .inputContainer {
    margin: 30px 0;
  }

  .nextBtn {
    margin: 50px 0 10px 0;
  }

  .skipBtn {
    margin-top: 10px;
  }

  .logo {
    height: 30px;
    margin: 10px 0;
  }

  .leftPane {
    position: initial;
    width: 100vw !important;
    min-height: 200px;
    height: fit-content;
    padding: 20px;
  }

  .rightPane {
    position: relative;
    left: 0 !important;
    width: 100vw !important;
    padding: 20px;
    height: fit-content;
  }

  .nextBtn, .backBtn, .continueBtn, .skipBtn {
    position: initial;
  }

  .inactiveSection {
    display: none;
  }

  .activeSection, .inactiveSection, .sectionCompleteContainer h2 {
    font-size: 25px;
  }

  .questionText, .headingText, .inputContainer {
    width: 80%;
  }

  .headingText, .sectionCompleteContainer h3 {
    font-size: 22px;
  } 

  .sectionCompleteContainer h2, .sectionCompleteContainer h3 {
    width: 80%;
  }

  .numberInputContainer p {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .spinnerContainer {
    height: 30px;
    width: 30px;
  }

  .sectionCompleteContainer h2 {
    font-size: 20px;
    width: 90%;
  }

  .sectionCompleteContainer h3 {
    font-size: 18px;
    width: 90%;
    margin-bottom: 30px;
  }
}
